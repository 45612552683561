
@import '~antd/dist/reset.css';
@import 'Login.css';

.margin-btn-steps {
  margin:10px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;  
}

.steps-action{
  margin-top: 24px;
}

body{
  background:#001529;
}
.logo {
  width: 120px;
  background:transparent;
  margin: 16px 28px 16px 28px;
  float: left;
}

.logo_login {
  width: 50%;
  background: black;
}

.App {
  text-align: center;
}



.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-select-container .ant-select .ant-select-selector {
  border-radius:10px;
}
